html {
  height: calc(100vh - 84px);
  margin-top: 84px;
  overflow: hidden;
}

body {
  height: 100%;
  overflow-y: auto;
}

#jumbo {
  padding: 1rem 1rem;
}

#camera--output {
  /*position: fixed;*/
  /* height: 100%;
  width: 100%;
  margin: 50px 50px; */
  object-fit: cover;
}

#camera--view {
  transform: scaleX(-1);
  filter: FlipH;
  max-width: 100%;
  border-radius: 10px;
}

.cameraSelect {
  max-width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
}

#camera--trigger {
  width: 200px;
  background-color: #00ad7e;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 5px;
  left: calc(50% - 100px);
}
.hidden {
  display: none;
}
.taken {
  position: fixed;
  height: 100px !important;
  width: 100px !important;
  transition: all 0.5s ease-in;
  border: solid 3px white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  bottom: 60px;
  left: 5px;
  z-index: 2;
}

.wrapper {
  display: grid;
  grid-template-columns: 40% 40% 20%;
}

.wrapperHdr {
  display: grid;
  grid-template-columns: 40% 40% 20%;
}

.blinking {
  animation: blinkingText 1.2s infinite;
  width: 200px;
  background-color: #009fdb;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  padding: 15px 20px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 30px;
  left: calc(50% - 100px);
}

.blinkingMsg {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #fff;
  }
  49% {
    color: #fff;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #fff;
  }
}
